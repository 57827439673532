.loader-holder {
  display: flex;
  justify-content: center;
  align-items: center;

  .loader-content {
    background: #f7f7f7;
    border-radius: 5px;
    padding: 25px;
    width: 308px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.loader {
  width: 24px;
  height: 24px;
  display: inline-block;
  position: relative;
  transform: rotate(45deg);
}
.loader::before {
  content: "";
  box-sizing: border-box;
  width: 12px;
  height: 12px;
  position: absolute;
  left: 0;
  top: -12px;
  animation: animloader 4s ease infinite;
}
.loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 12px;
  height: 12px;
  background: rgba(255, 255, 255, 0.85);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  animation: animloader2 2s ease infinite;
}

@keyframes animloader {
  0% {
    box-shadow: 0 12px rgba(255, 255, 255, 0), 12px 12px rgba(255, 255, 255, 0),
      12px 24px rgba(255, 255, 255, 0), 0px 24px rgba(255, 255, 255, 0);
  }
  12% {
    box-shadow: 0 12px $dark, 12px 12px rgba(255, 255, 255, 0),
      12px 24px rgba(255, 255, 255, 0), 0px 24px rgba(255, 255, 255, 0);
  }
  25% {
    box-shadow: 0 12px $dark, 12px 12px $dark, 12px 24px rgba(255, 255, 255, 0),
      0px 24px rgba(255, 255, 255, 0);
  }
  37% {
    box-shadow: 0 12px $dark, 12px 12px $dark, 12px 24px $dark,
      0px 24px rgba(255, 255, 255, 0);
  }
  50% {
    box-shadow: 0 12px $dark, 12px 12px $dark, 12px 24px $dark, 0px 24px $dark;
  }
  62% {
    box-shadow: 0 12px rgba(255, 255, 255, 0), 12px 12px $dark, 12px 24px $dark,
      0px 24px $dark;
  }
  75% {
    box-shadow: 0 12px rgba(255, 255, 255, 0), 12px 12px rgba(255, 255, 255, 0),
      12px 24px $dark, 0px 24px $dark;
  }
  87% {
    box-shadow: 0 12px rgba(255, 255, 255, 0), 12px 12px rgba(255, 255, 255, 0),
      12px 24px rgba(255, 255, 255, 0), 0px 24px $dark;
  }
  100% {
    box-shadow: 0 12px rgba(255, 255, 255, 0), 12px 12px rgba(255, 255, 255, 0),
      12px 24px rgba(255, 255, 255, 0), 0px 24px rgba(255, 255, 255, 0);
  }
}

@keyframes animloader2 {
  0% {
    transform: translate(0, 0) rotateX(0) rotateY(0);
  }
  25% {
    transform: translate(100%, 0) rotateX(0) rotateY(180deg);
  }
  50% {
    transform: translate(100%, 100%) rotateX(-180deg) rotateY(180deg);
  }
  75% {
    transform: translate(0, 100%) rotateX(-180deg) rotateY(360deg);
  }
  100% {
    transform: translate(0, 0) rotateX(0) rotateY(360deg);
  }
}
