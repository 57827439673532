$light: #ffffff;
$dark: #ee6e00;
$red: #ffd3c4;
$green: #d1ffc4;
$border: rgba(0, 0, 0, 0.2);
$white: #ffffff;
$disabled: rgba(0, 0, 0, 0.1);
$danger: #d43f3a;
$brand-danger: #d9534f !default;

@import 'https://fonts.googleapis.com/css?family=Indie+Flower|Nunito:400,600,800';

@import 'reset';
@import 'typography';
@import 'alert';
@import 'button';
@import 'loader';

* {
  box-sizing: border-box;
}

html,
body {
  &.noscroll {
    overflow: hidden;
  }
}

.hide,
.hidden {
  display: none !important;
}

.form-group {
  margin-bottom: 15px;
}

body {
  background: url('../img/bg-1.jpg') #ffffff center center fixed no-repeat;
  background-size: cover;
  font-family: 'Nunito', sans-serif;
  text-align: center;

  &.loggedout {
    background: #f7f7f7;
  }

  .password,
  .user-password {
    background: #f7f7f7;
    border-radius: 5px;
    height: 425px;
    list-style: none;
    margin: 0 -154px 0 0;
    padding: 25px;
    position: fixed;
    right: 50%;
    top: 100px;
    transition: right 0.5s ease;
    width: 308px;
    z-index: 4;
  }

  &:not(.loggedout) {
    &.bg-0 {
      background-image: url('../img/bg-0.jpg');
    }
    &.bg-1 {
      background-image: url('../img/bg-1.jpg');
    }
    &.bg-2 {
      background-image: url('../img/bg-2.jpg');
    }
    &.bg-3 {
      background-image: url('../img/bg-3.jpg');
    }
    &.bg-4 {
      background-image: url('../img/bg-4.jpg');
    }
    &.bg-5 {
      background-image: url('../img/bg-5.jpg');
    }
    &.bg-6 {
      background-image: url('../img/bg-6.jpg');
    }
  }
}

.login,
.change-password,
.change-user-password {
  display: inline-block;
  max-width: 307px;
  padding: 60px 0;

  .container {
    display: flex;
    min-height: 200px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  svg {
    margin: 0 0 30px;

    path {
      fill: $dark;
      transform: scale(3, 3);
    }
  }

  .form-group {
    padding: 20px 0 0 0;
    position: relative;

    label {
      font-size: 16px;
      font-weight: 400;
      left: 0;
      position: absolute;
      top: 30px;
      z-index: 1;
    }

    input {
      &[type='text'],
      &[type='password'] {
        background: transparent;
        border-bottom: 1px solid $border;
        border-left: 0;
        border-radius: 0;
        border-right: 0;
        border-top: 0;
        color: #333333;
        font-size: 16px;
        font-weight: 400;
        padding: 10px 1px;
        position: relative;
        width: 100%;
        z-index: 2;

        &.error {
          border-color: $danger;
          color: $danger;

          & + label {
            color: $danger;
          }
        }

        &:focus,
        &:valid {
          outline: none;

          & + label {
            font-size: 12px;
            left: 0;
            top: 5px;
            transition: top 0.2s ease-out 0s, left 0.2s ease-out 0s,
              font 0.2s ease-out 0s;
          }
        }
      }
    }
  }
}

.change-password,
.change-user-password {
  padding: 0;

  .alert {
    margin-bottom: 10px;
  }

  .form-group {
    &:last-child {
      padding-top: 10px;
    }
  }

  svg {
    margin: 0;

    path {
      transform: scale(0.8, 0.8);
    }
  }
}

h1 {
  color: $white;
  font-family: 'Indie Flower', cursive;
  font-size: 38px;
  margin: 30px 0 0;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
}

.btn {
  text-decoration: none;
}

nav {
  background: $dark;
  border-radius: 3px;
  height: 40px;
  position: fixed;
  right: 20px;
  top: 20px;
  width: 40px;
  z-index: 5;

  &.open {
    ul {
      right: 50%;
    }

    .toggle {
      span {
        background: none;
        box-shadow: none;

        &::after {
          top: 0;
          transform: rotate(45deg);
        }

        &::before {
          top: 0;
          transform: rotate(-45deg);
        }
      }
    }
  }

  .toggle {
    display: block;
    height: 40px;
    position: fixed;
    right: 20px;
    top: 20px;
    width: 40px;
    z-index: 6;

    span {
      background: $white;
      border-radius: 1px;
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
      display: block;
      height: 4px;
      margin-top: -2px;
      position: absolute;
      right: 7px;
      top: 50%;
      transition: background 0.2s ease-out 0s;
      width: 26px;

      &::after {
        background: $white;
        border-radius: 1px;
        content: '';
        height: 4px;
        left: 0;
        position: absolute;
        top: 8px;
        transition: transform 0.2s ease-out 0s, background 0.2s ease-out 0s;
        width: 100%;
      }

      &::before {
        background: $white;
        border-radius: 1px;
        content: '';
        height: 4px;
        left: 0;
        position: absolute;
        top: -8px;
        transition: transform 0.2s ease-out 0s, background 0.2s ease-out 0s;
        width: 100%;
      }
    }
  }

  ul {
    background: #f7f7f7;
    border-radius: 5px;
    height: 425px;
    list-style: none;
    margin: 0 -154px 0 0;
    padding: 25px;
    position: fixed;
    right: -350px;
    top: 100px;
    transition: right 0.5s ease;
    width: 308px;
    z-index: 5;

    li {
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      position: relative;

      &:last-child {
        border: 0;
        bottom: 25px;
        left: 0;
        padding: 0 25px;
        position: absolute;
        width: 100%;

        a {
          background: $brand-danger;
          border-radius: 3px;
          color: $white;
          display: block;
          padding: 10px 10px 10px 45px;
          position: relative;

          &:hover {
            background: $brand-danger;

            svg {
              path {
                fill: $white;
              }
            }
          }

          svg {
            top: 10px;

            path {
              fill: $white;
            }
          }
        }
      }

      a {
        color: #333333;
        display: block;
        font-size: 14px;
        padding: 15px 15px 15px 45px;
        text-align: left;
        text-decoration: none;
        transition: background 0.2s ease-out 0s;

        svg {
          left: 0;
          margin: 0 10px;
          position: absolute;
          top: 15px;

          path {
            fill: #999999;
            transform: scale(0.8, 0.8);
            transition: fill 0.2s ease-out 0s;
          }
        }

        &:hover {
          background: $white;

          svg {
            path {
              fill: $dark;
            }
          }
        }
      }
    }
  }
}

.guide {
  background: #f7f7f7;
  border-bottom: 2px solid #ffffff;
  box-shadow: 0 0 15px #000000;
  color: #333333;
  font-size: 13px;
  font-weight: 800;
  height: 0;
  left: 0;
  overflow: hidden;
  padding: 30px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;

  &.open {
    height: auto;
    transition: height 2s;
  }
}

.calendar-holder {
  background: rgba(255, 255, 255, 0.8);
  border-radius: 3px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  display: inline-block;
  margin: 15px 10px;
  padding: 5px;
  position: relative;

  .info {
    background: #f7f7f7;
    border-radius: 5px;
    font-size: 14px;
    height: 100%;
    left: 0;
    padding: 30px;
    position: absolute;
    text-align: left;
    top: 0;
    width: 100%;

    &::after {
      background: #333333;
      border-radius: 3px;
      content: '';
      height: 3px;
      position: absolute;
      right: 15px;
      top: 20px;
      transform: rotate(-45deg);
      width: 18px;
    }

    &::before {
      background: #333333;
      border-radius: 3px;
      content: '';
      height: 3px;
      position: absolute;
      right: 15px;
      top: 20px;
      transform: rotate(45deg);
      width: 18px;
    }
  }

  .header {
    background: $dark;
    border-bottom: 1px solid $border;
    color: $white;
    font-size: 20px;
    font-weight: 800;
    padding: 15px;
    position: relative;
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.2);
    text-transform: uppercase;

    a {
      color: $white;
      display: block;
      font-size: 24px;
      height: auto;
      left: 5px;
      line-height: 12px;
      padding: 19px 0;
      position: absolute;
      text-align: center;
      text-decoration: none;
      top: 2px;
      width: 35px;

      &.next-month {
        left: auto;
        right: 5px;
      }
    }
  }

  .weeks {
    position: relative;

    .week {
      border-right: 1px solid $border;
      overflow: hidden;
      position: relative;

      .day {
        background: $green;
        border-bottom: 1px solid $border;
        border-left: 1px solid $border;
        color: #444444;
        cursor: pointer;
        display: block;
        float: left;
        font-size: 14px;
        font-weight: 600;
        padding: 11px 0;
        position: relative;
        text-align: center;
        width: 37px;

        &.disabled {
          background: $disabled;
          color: #888888;
        }

        &.prevented {
          background: $disabled !important;
          color: #888888;
        }

        &.reserved {
          background: $red;
        }

        &.active {
          background: $dark;
          color: $white;
        }

        &.today {
          &::after {
            border: 3px solid $white;
            content: '';
            display: block;
            height: calc(100% - 6px);
            position: absolute;
            top: 0;
            width: calc(100% - 6px);
          }
        }

        &.week-number {
          background: #ee6e00;
          font-size: 11px;
          height: 42px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 900;
          color: #fff;
          font-family: 'Nunito', sans-serif;
          border-right: 1px solid rgba(0, 0, 0, 0.2);

          & + div {
            border-left: 0;
          }
        }
      }
    }
  }
}

.disclaimer {
  background: $white;
  border-radius: 3px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  color: #333333;
  display: inline-block;
  font-size: 14px;
  margin: 0 0 30px;
  max-width: 307px;
  padding: 15px;
}
