html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
b,
strong {
  font-weight: 700;
}

p {
  margin: 1em 0;
}
